<template>
  <div class="quiz-loader-wrapper">
    <radial-progress-bar
        class="m-auto"
        :diameter="125"
        :completed-steps="progress"
        :total-steps="100"
        :stroke-width="8"
        :inner-stroke-width="8"
        :animate-speed="100"
        start-color="#54A96C"
        stop-color="#54A96C"
        innerStrokeColor="#FFF"
    >
      <div class="legend-custom-style">{{ progress }}%</div>
    </radial-progress-bar>
    <div class="title mt-5">{{ $t('quiz.your_answers_processed') }}...</div>
    <div class="steps-wrapper d-flex flex-column align-items-center mt-3">
      <div class="text-left d-inline-block">
        <div v-for="(step, idx) in steps" :key="idx">
          <div v-if="progress >= step.from" class="d-flex align-items-center my-2">
            <img src="@/assets/img/icons/check-green.svg" alt="">
            <div class="mx-3">{{ $t(`quiz.${step.title}`) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress';

export default {
  components: {
    RadialProgressBar
  },
  props: {
    loadingStatus: {
      type: String,
      default: null
    },
    startValue: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    progressInterval: 0,
    progress: 0,
    steps: [
      {from: 10, title: 'analyzing_your_profile'},
      {from: 23, title: 'generating_possible_options'},
      {from: 70, title: 'preparing_the_best_offer'}
    ]
  }),
  beforeMount() {
    this.progress = this.startValue;
  },
  mounted() {
    this.progressInterval = setInterval(() => {
      let i = Math.floor(Math.random() * 2) + 1;
      this.progress += i;
    }, 100);
  },
  watch: {
    progress: {
      immediate: true,
      handler(val) {
        if (this.loadingStatus === 'pre' && val > 55) {
          this.$emit('preLoadingFinished', val);
          clearInterval(this.progressInterval);
        }
        if (val > 99) {
          this.progress = 100;
          clearInterval(this.progressInterval);
          setTimeout(() => this.$router.push({name: 'congratulations'}), 1000);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quiz-loader-wrapper::v-deep {
  .radial-progress-container {
    .legend-custom-style {
      font-weight: 700;
    }
  }

  .title {
    font-size: 28px;
    font-weight: 600;
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .quiz-loader-wrapper::v-deep {
    .title {
      font-size: 24px;
    }
  }
}
</style>