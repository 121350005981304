<template>
  <div class="quiz-page-wrapper m-auto pt-3 mb-5" :class="{'quiz-page-width': !loadingStatus}">
    <div v-if="!loadingStatus && questions.length">


      <div class="quiz-progress-bar position-relative mb-5">
        <!--                <img-->
        <!--                    v-if="questionNumber > 1"-->
        <!--                    src="@/assets/img/icons/single-arrow-left.svg"-->
        <!--                    class="arrow-left"-->
        <!--                    @click="backToPreviousQuestion"-->
        <!--                />-->

        <i v-if="questionNumber > 1" class="arrow-left" @click="backToPreviousQuestion"></i>
        <b-progress :value="questionNumber / questions.length * 100" :max="100" height="3px" variant="success"/>
        <div class="status px-3"><span>{{ `${questionNumber}` }}</span>{{ ` / ${questions.length}` }}</div>
      </div>


      <h2 class="quiz-title mb-5">
        {{ activeQuestion.question }}
      </h2>
      <div class="quiz-answers-wrapper d-flex flex-wrap justify-content-between align-items-center"
           :class="{'flex-column': activeQuestion.answers && activeQuestion.answers.length<=5}">
        <div
            v-for="answer in activeQuestion.answers"
            :key="answer.id"
            class="answer position-relative d-flex justify-content-between align-items-center w-100 px-5"
            :class="[
                {selected: activeAnswer.length && activeAnswer.includes(answer.id)},
                {'more-than-five-answers': activeQuestion.answers && activeQuestion.answers.length>5}
                ]"
            @click="answerPicked(answer.id)"
        >
          <span class="position-absolute" :class="{'fake-checkbox': activeQuestion.type === 2}"/>
          {{ answer.value }}
        </div>
      </div>
      <b-button
          v-if="activeQuestion.type === 2"
          class="continue-btn fw-bolder py-3 mt-4"
          :disabled="!activeAnswer.length"
          @click="questionAnswered"
      >
        {{ $t('quiz.continue') }}
      </b-button>
    </div>
    <quiz-loader
        v-if="loadingStatus"
        :loading-status="loadingStatus"
        :start-value="progress"
        @preLoadingFinished="preLoadingFinished"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import {mapGetters} from 'vuex';
import config from '@/config';
import QuizLoader from '@/components/QuizLoader';

export default {
  components: {
    QuizLoader
  },
  data: () => ({
    activeAnswer: [],
    activeQuestion: {},
    allQuestionsAnswered: false,
    answers: {},
    loadingStatus: null,
    previousProgress: 0,
    progress: 0,
    idx: null
  }),
  async beforeMount() {
    this.initFBPixel(this.fbPixelId);
    this.initClarity(config.clarityId.onboardingId)
    Vue.use(VueGtag, {config: {id: config.gaId.onboarding.prod}});

    await this.$store.dispatch('appStore/getQuestions');

    if (localStorage.getItem('questionId')) {
      this.idx = parseInt(localStorage.getItem('questionId'));
    }
    if (localStorage.getItem('answers')) {
      this.answers = JSON.parse(localStorage.getItem('answers'));
    }
  },
  mounted() {
    this.scrollToTop();
    this.pixelEvent('PageView');
  },
  computed: {
    ...mapGetters({
      questions: 'appStore/questions'
    }),
    questionNumber() {
      return this.activeQuestion?.number || 1;
    },
    currentAnswer() {
      return Object.keys(this.answers).length && this.answers[this.activeQuestion.id] ? this.answers[this.activeQuestion.id] : [];
    },
    fbPixelId() {
      return window.location.host === config.siteHost ? config.fbPixel.onboarding.prod : config.fbPixel.onboarding.dev;
    }
  },
  watch: {
    idx(idx) {
      localStorage.setItem('questionId', idx);
      if (this.questions.length) {
        this.activeQuestion = this.questions[idx];
        this.activeQuestion.number = idx + 1;

        // this.amplitudeEvent(`${this.activeQuestion.original_question.replace(/[:?]/g, '')} shown`);
        this.amplitudeEvent(`${this.activeQuestion.question.replace(/[:?]/g, '')} shown`);
        this.gtagEvent('Page_View', {event_category: 'View', event_label: `Page_View_Quiz${this.questionNumber}`});
      }
    },
    questions(data) {
      if (data) {
        if (!this.idx) {
          this.idx = parseInt(localStorage.getItem('questionId')) || 0;
        }
        localStorage.setItem('questions', JSON.stringify(data));
      }
    },
    questionNumber(id) {
      if (id) {
        this.activeAnswer = [];
        if (this.currentAnswer.length) {
          this.currentAnswer.forEach((item, idx) => {
            this.$set(this.activeAnswer, idx, item);
          });
        }
        if (id === this.questions.length) {
          this.loadingStatus = 'pre';
          this.progress = 0;
        }
      }
    },
    allQuestionsAnswered(val) {
      if (val) this.loadingStatus = 'full';
    },
    answers(data) {
      if (data) {
        this.currentAnswer.forEach((item, idx) => {
          this.$set(this.activeAnswer, idx, item);
        });
      }
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    answerPicked(answerId) {
      if (this.activeQuestion.type === 2) {
        this.activeAnswer.includes(answerId)
            ? this.$delete(this.activeAnswer, this.activeAnswer.indexOf(answerId))
            : this.$set(this.activeAnswer, this.activeAnswer.length, answerId);
      } else {
        this.$set(this.activeAnswer, 0, answerId);
        this.questionAnswered();
      }
    },
    questionAnswered() {
      this.$set(this.answers, this.activeQuestion.id, this.activeAnswer);
      localStorage.setItem('answers', JSON.stringify(this.answers));

      this.activeAnswer.forEach(item => {
        // this.amplitudeEvent(`${this.activeQuestion.original_question.replace(/[:?]/g, '')} answered`, {
        this.amplitudeEvent(`${this.activeQuestion.question.replace(/[:?]/g, '')} answered`, {
          // answer: this.activeQuestion.answers.find(el => el.id === item).original_value
          answer: this.activeQuestion.answers.find(el => el.id === item).value
        });
      });

      this.$store.dispatch('appStore/saveAnswer', {
        questionId: this.activeQuestion.id, answerIds: this.answers[this.activeQuestion.id]
      });

      if (this.questionNumber === this.questions.length) {
        localStorage.removeItem('questionId');
        this.allQuestionsAnswered = true;
        return false;
      }
      this.idx++;
      this.scrollToTop();
    },
    backToPreviousQuestion() {
      this.amplitudeEvent('Back clicked', {placement: this.activeQuestion.question});
      if (this.idx > 0) this.idx--;
    },
    preLoadingFinished(progress) {
      this.loadingStatus = null;
      this.progress = progress;
    }

  }
};
</script>

<style lang="scss" scoped>
.quiz-page-wrapper {
  min-height: calc(100vh - 250px);

  .progress {
    width: 100%;
  }

  i {
    border: solid rgba(31, 27, 26, 0.2);;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
  }

  .arrow-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-right: 4px;
    cursor: pointer;
  }

  &.quiz-page-width {
    max-width: 580px;
  }

  .quiz-title {
    font-weight: 600;
  }

  .quiz-progress-bar {
    display: flex;
    align-items: center;
    height: 8px;

    .status {
      position: absolute;
      top: 50%;
    }

    .status {
      right: 0;
      transform: translate(100%, -50%);
      color: rgba(31, 27, 26, .2);
      font-weight: 700;
      font-size: 14px;

      span {
        color: #54a96c;
      }
    }
  }

  .quiz-answers-wrapper {
    .answer {
      height: 75px;
      margin-bottom: 20px;
      background-color: #FFFFFF;
      font-weight: 500;
      border: 2px solid #FFFFFF;
      border-radius: 10px;
      box-shadow: 0 3px 12px rgba(24, 25, 29, 0.06);
      cursor: pointer;

      .fake-checkbox {
        height: 30px;
        width: 30px;
        top: 24px;
        right: 42px;
        border: 1px solid #adb5bd;
        border-radius: 20px;
      }

      &.more-than-five-answers {
        width: 45% !important;
        flex-wrap: wrap;
        margin: 10px;
      }

      &.selected {
        color: #54A96C;
        border-color: #54A96C;

        &::after {
          content: 'L';
          font-size: 28px;
          -ms-transform: scaleX(-1) rotate(-42deg); /* IE 9 */
          -webkit-transform: scaleX(-1) rotate(-42deg); /* Chrome, Safari, Opera */
          transform: scaleX(-1) rotate(-42deg);
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .quiz-page-wrapper {
    .quiz-answers-wrapper {
      .answer {
        &:hover {
          box-shadow: 2px 4px 12px rgba(24, 25, 29, 0.3);
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {

  .status {
    font-size: 12px !important;
    transform: translate(30%, -50%) !important;
  }

  .progress {
    width: 86% !important;
  }

  .quiz-title {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .quiz-answers-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .quiz-page-wrapper .quiz-answers-wrapper .answer.more-than-five-answers {
    width: 100% !important;
    flex-wrap: wrap;
    margin: 10px 0;
  }

  .answer {
    padding-right: 2rem !important;
    padding-left: 1rem !important;
  }

  .quiz-page-wrapper {
    min-height: calc(100vh - 300px);
    padding: 5px 25px !important;

    .quiz-progress-bar {
      width: 100%;
      //margin: auto;
    }
  }
}
</style>